import React from 'react';
import AppRouter from './Router';
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose your theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";
import './index.css';
import Footer from './components/Footer';
import { AnalyticsTracker } from './components/AnalyticsTracker';

const App: React.FC = () => {
  return (
    <div className="">
      <AppRouter />
      <Footer />
    </div>
  );
};

export default App;
