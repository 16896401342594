import { Button } from "primereact/button";

import React from "react";
import { FaUbuntu } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import { IoLogoGameControllerA } from "react-icons/io";
import PricingPlan from "../../components/PricingPlan";
import FAQSection from "../../components/FAQSection";
import { FaGithub } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";

const plans = [
  {
    tier: "Tier 1",
    cpu: "1 vCPU",
    ram: "1GB",
    drive: "25GB SSD",
    bandwidth: "1TB",
    price: "2,750",
  },
  {
    tier: "Tier 2",
    cpu: "2 vCPU",
    ram: "2GB",
    drive: "50GB SSD",
    bandwidth: "2TB",
    price: "5,500",
  },
  {
    tier: "Tier 3",
    cpu: "4 vCPU",
    ram: "4GB",
    drive: "80GB SSD",
    bandwidth: "4TB",
    price: "11,000",
  },
  {
    tier: "Tier 4",
    cpu: "6 vCPU",
    ram: "8GB",
    drive: "320GB SSD",
    bandwidth: "6TB",
    price: "38,500",
  },
];

const faqs = [
  {
    question: "What is the return policy?",
    answer:
      "Our return policy allows you to return items within 30 days of purchase. Please ensure the items are in original condition.",
  },
  {
    question: "How do I track my order?",
    answer:
      "You can track your order using the tracking link provided in your order confirmation email.",
  },
  {
    question: "Do you offer customer support?",
    answer:
      "Yes, we offer 24/7 customer support. You can reach us via email, phone, or live chat.",
  },
  {
    question: "Can I change my shipping address?",
    answer:
      "Yes, you can change your shipping address before the order is dispatched. Please contact our support team to assist you with this.",
  },
];

const EmailPage: React.FC = () => {
  return (
    <div>
      <div className="grid grid-nogutter text-800 bg-[#E2E2E2]   pl-0 sm:pl-[10rem]">
        <div className="col-12 md:col-4 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] sm:pl-[10rem]">
          <section className="mt-[0rem]">
            <span className="block text-5xl font-bold mb-3 text-[#d50036]">
              Website
            </span>
            <p className="-mt-[1rem] ml-0 mb-3 text-[0.7rem]">
              {" "}
              Seamlessly integrated with Console for full control.
            </p>
            <p className="mt-0 mb-4 text-700 line-height-3  pb-5">
              Our Virtual Machine offers a dynamic and scalable environment for
              your computing needs.
              <ul className="pt-4">
                <li className="w-100 py-1">
                  <FaGithub className="inline mr-2 text-[#d50036]" /> Optimum
                  Control.
                </li>
                <li className="w-100 py-1">
                  {" "}
                  <FaNodeJs className="inline mr-2 text-[#D50036]" /> Scale
                  Resources effortlessly.
                </li>
                <li className="w-100 py-1">
                  <FaWordpress className="inline mr-2 text-[#D50036]" /> Linux
                  and Windows.
                </li>
              </ul>
            </p>
            <span className="block text-6xl font-thin mb-1 ml-2 -mt-5 text-[#d50036]">
              <span className="font-bold text-sm">₦</span>2,750/
              <span className="font-bold text-sm">Month</span>
            </span>
            <Button
              label="SEE PLANS"
              className="animate-pulse p-button-primary px-10 py-1 rounded-full bg-[#d50036] text-white mt-5 text-sm"
            />
          </section>
        </div>
        <div className="col-12 md:col-8 overflow-hidden bg-[#e2e2e2]">
          <img
            src="/website/canvas-2-min.jpg"
            alt="website"
            className="md:ml-auto block md:m-h-full w-fit object-cover opacity-75 h-[80%] mt-5"
            style={{ clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)" }}
          />
        </div>
      </div>

      <div className="flex justify-between px-5 py-5 bg-red bg-gradient-custom-2">
        <div className="flex-1 p-2">
          <h2 className="md:text-lg text-md mb-2 text-white font-thin">
            Heading 1
          </h2>
          <p className="md:text-sm text-[12px] text-white">
            Description for Heading 1
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="md:text-lg text-md mb-2 text-white font-thin">
            Heading 1
          </h2>
          <p className="md:text-sm text-[12px] text-white">
            Description for Heading 1
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="md:text-lg text-md mb-2 text-white font-thin">
            Heading 1
          </h2>
          <p className="md:text-sm text-[12px] text-white">
            Description for Heading 1
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="md:text-lg text-md mb-2 text-white font-thin">
            Heading 1
          </h2>
          <p className="md:text-sm text-[12px] text-white">
            Description for Heading 1
          </p>
        </div>
      </div>
      <PricingPlan plans={plans} productLink={"test"} />
      <FAQSection faqs={faqs} />
    </div>
  );
};

export default EmailPage;
