import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import MegaMenu from "./MegaMenu";
import {
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import DropdownMenu from "./Dropdown";
import NewNavSubmenu from "./NavSubmenu";
import { IoMdClose } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";

const Navbar: React.FC = () => {
  const [showMegaMenu, setShowMegaMenu] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement | null>(null); // Reference for the menu container
  const hoverRef = useRef<number | NodeJS.Timeout | null>(null);

  const menuItems = [
    {
      name: "Virtual Infrastructure Services",
      items: [
        {
          title: "Compute",
          path: "",
          icon: "/vm.png",
          subItems: [
            {
              title: "Virtual Machine",
              path: "/compute/virtual-machine",
              icon: "/vm.png",
            },
            {
              title: "Serverless",
              path: "/compute/serverless",
              icon: "/serverless.png",
            },
            { title: "DaaS", path: "/compute/daas", icon: "/daas.png" },
          ],
        },
        {
          title: "Virtual Data Center",
          path: "/virtual-data-center",
          icon: "/data-center.png",
        },
        { title: "Storage (FTP)", path: "/storage/ftp", icon: "/storage.png" },
      ],
    },
    {
      name: "Web Services",
      items: [
        {
          title: "Hosting",
          path: "",
          icon: "/hosting.png",
          subItems: [
            {
              title: "Shared Hosting",
              path: "/hosting/shared",
              icon: "/shared-hosting.png",
            },
            { title: "VPS Hosting", path: "/hosting/vps", icon: "/vps.png" },
          ],
        },
        { title: "Email", path: "/email", icon: "/email.png" },
        { title: "Domains", path: "/domains", icon: "/domains.png" },
      ],
    },
    {
      name: "PaaS",
      items: [{ title: "DBaaS", path: "/dbaas", icon: "/dbaas.png" }],
    },
  ];

  useEffect(() => {
    // Close the menu when the location changes
    setIsMobileMenuOpen(false);
    setShowMegaMenu(null);
  }, [location]);

  useEffect(() => {
    // Function to handle click outside the menu
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (menu: string) => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current as number);
    }
    setShowMegaMenu(menu);
  };

  const handleMouseLeave = () => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current as number);
    }
    hoverRef.current = window.setTimeout(() => {
      setShowMegaMenu(null);
    }, 300);
  };

  const handleMobileMouseLeave = () => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current as number);
    }
    hoverRef.current = window.setTimeout(() => {
      setShowMegaMenu(null);
    }, 300);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const start = (
    <div className="flex items-center">
      <div
        className="text-2xl font-bold text-accent cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img
          src="/logo-light.svg"
          alt="logo"
          className="py-5 w-[10rem] sm:w-[10rem] md:w-[10rem] lg:w-[10rem]"
        />
      </div>
    </div>
  );

  const end = (
    <Button
      label="Login"
      className="p-button-primary px-4 py-2 rounded-[0.25rem] faint-purple-dark text-white text-sm"
      onClick={() =>
        (window.location.href = "https://portal.suburbancloud.ng/login")
      }
    />
  );

  return (
    <nav className=" bg-black" ref={menuRef}>
      <div className="flex justify-between items-center p-1 px-4 py-0">
        {start}
        <div className="hidden md:flex space-x-6 text-white text-sm">
          {menuItems.map((menu) => (
            <div
              key={menu.name}
              className="relative cursor-pointer "
              onMouseEnter={() => handleMouseEnter(menu.name)}
              onMouseLeave={handleMouseLeave}
              aria-haspopup="true"
              aria-expanded={showMegaMenu === menu.name}
            >
              <span>{menu.name}</span>
              <ChevronDownIcon className="h-3 inline-block ml-2 bg-red" />
              {showMegaMenu === menu.name && (
                <div
                  className="absolute left-0 w-full"
                  onMouseLeave={handleMouseLeave}
                >
                  <MegaMenu items={menu.items} title={menu.name} />
                </div>
              )}
            </div>
          ))}
          <div
            className="cursor-pointer text-sm"
            onClick={() => navigate("/about")}
          >
            About Us
          </div>
          <div
            className="cursor-pointer text-sm"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </div>
        </div>
        <div className="flex items-center space-x-6">
          <DropdownMenu />
          <div className="hidden md:block"> {end}</div>
          <div className="md:hidden flex flex-row justify-end">
            {isMobileMenuOpen ? (
              <motion.div key="close-menu-icon">
                <IoMdClose
                  onClick={toggleMobileMenu}
                  className="text-5xl cursor-pointer float-right text-[#FF0021]"
                />
              </motion.div>
            ) : (
              <motion.div>
                <Bars3Icon
                  className="h-10 text-accent cursor-pointer float-right stroke-[#FF0021]"
                  onClick={toggleMobileMenu}
                />
              </motion.div>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div key="mobile-nav-bar" className="md:hidden p-4 bg-[#000]">
            <ul className="space-y-4 text-white">
              {menuItems.map((menu) => (
                <li key={menu.name} className="cursor-pointer p-2 border-b border-dashed border-gray-900">
                  <div
                    className="w-full justify-between flex items-center"
                    onClick={() => {
                      if (showMegaMenu === menu.name) {
                        handleMobileMouseLeave();
                      } else {
                        handleMouseEnter(menu.name);
                      }
                    }}
                  >
                    {menu.name}
                    {showMegaMenu === menu.name ? (
                      <ChevronUpIcon className="h-5 inline-block ml-2 bg-red" />
                    ) : (
                      <ChevronDownIcon className="h-5 inline-block ml-2 bg-red" />
                    )}
                  </div>
                  {showMegaMenu === menu.name && (
                    <NewNavSubmenu
                      items={menu.items}
                      title={menu.name}
                      onMouseLeave={handleMobileMouseLeave}
                    />
                  )}
                </li>
              ))}
              <li className="cursor-pointer p-2 border-b border-dashed border-gray-900" onClick={() => navigate("/about")}>
                About Us
              </li>
              <li
                className="cursor-pointer p-2 border-b border-dashed border-gray-900"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </li>
            </ul>
            <div className="mt-4">{end}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
