import { Button } from "primereact/button";

import React, { useState, useEffect, useRef } from "react";
import PricingPlan from "../../../components/PricingPlan";
import FAQSection from "../../../components/FAQSection";
import { FaGithub } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
import { BreadCrumb } from "primereact/breadcrumb";
import { axiosClient } from "../../../services/axios-client";
import { Product } from "../../../types";

const allPlans = [
  {
    tier: "Tier 1",
    cpu: "1 vCPU",
    ram: "1GB",
    drive: "25GB SSD",
    bandwidth: "1TB",
    price: "2,750",
  },
  {
    tier: "Tier 2",
    cpu: "2 vCPU",
    ram: "2GB",
    drive: "50GB SSD",
    bandwidth: "2TB",
    price: "5,500",
  },
  {
    tier: "Tier 3",
    cpu: "4 vCPU",
    ram: "4GB",
    drive: "80GB SSD",
    bandwidth: "4TB",
    price: "11,000",
  },
  {
    tier: "Tier 4",
    cpu: "6 vCPU",
    ram: "8GB",
    drive: "320GB SSD",
    bandwidth: "6TB",
    price: "38,500",
  },
];

const faqs = [
  {
    question:
      "What applications can I deploy with the One Click Application Setup?",
    answer:
      "Our One Click Application Setup allows you to easily deploy Node.js servers, static HTML sites, WordPress, and PHP applications with minimal effort.",
  },
  {
    question: "How do I manage multiple websites on the VPS?",
    answer:
      "You can manage multiple websites using Virtual Hosting (VHost), which enables you to run multiple domains on a single server efficiently.",
  },
  {
    question: "Is SSL included with the VPS?",
    answer:
      "Yes, we provide free SSL certificates for all your domains to ensure secure and encrypted connections for your websites.",
  },
  {
    question: "Can I integrate my GitHub repositories with the VPS?",
    answer:
      "Absolutely. Our service allows you to connect your GitHub repositories for seamless code deployment and version control.",
  },
  {
    question: "What level of support is offered for the VPS?",
    answer:
      "We offer 24/7 customer support to assist you with any issues, from setup to maintenance and troubleshooting.",
  },
];

const items = [
  { label: "Web Services" },
  { label: "Hosting" },
  { label: "VPS" },
];
const home = { label: "Home", url: "/" };

const SESSION_KEY = "VPS Plans";

const VPSPage: React.FC = () => {
  const plansRef = useRef<HTMLDivElement>(null);

  const scrollToPlans = () => {
    if (plansRef.current) {
      plansRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Array of images for the slideshow
  const images = [
    "/vps/vps-sites-min.jpg",
    "/vps/vps-new-site.jpg",
    "/vps/vps-overview-min.jpg",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [plans, setPlans] = useState(() => {
    const data = sessionStorage.getItem(SESSION_KEY);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  });

  // Function to handle image change
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade-out
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true); // Start fade-in after image is changed
      }, 1000); // The fade-out transition duration
    }, 5000); // Switch images every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [images.length]);

  useEffect(() => {
    const initPlans = async () => {
      const client = axiosClient();
      const { data } = await client.get("/products/list-vps");
      const plansList = data.data.products.product.filter(
        (currentPlan: Product) => currentPlan.paytype !== "free"
      );
      setPlans(plansList);
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(plansList));
    };

    if (!plans) {
      initPlans();
    }
  }, []);

  return (
    <div>
      <BreadCrumb
        model={items}
        home={home}
        className="text-sm bg-none border-none"
      />

      <div className="grid grid-nogutter text-800 bg-[#23272b] h-50 pl-0 sm:pl-[10rem]">
        <div className="col-12 md:col-12 overflow-hidden bg-[#000]">
          <span className="text-5xl font-normal  text-center mx-auto w-100 text-white block z-10 relative top-[0.5rem]">
            VPS HOSTING
          </span>
          <p className="-mt-[1rem] ml-2 mb-3 text-[0.7rem] text-[#fff] text-center mx-auto relative top-[2.5rem] z-10">
            Seamlessly integrated with Console for full control.
          </p>
          <Button
            label="SEE PLANS"
            className="p-button-primary px-10 py-[1rem] rounded-full  text-white mt-[6rem] text-sm block mx-auto z-10"
            onClick={scrollToPlans}
          />

          {/* Image slideshow with fade effect */}
          <img
            src={images[currentImageIndex]}
            alt="hero-1"
            className="animate-pulse block object-cover -mt-[0rem] sm:-mt-[5rem] md:-mt-[5rem] lg:-mt-[10rem]"
            style={{ clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)" }}
          />
        </div>
      </div>
      {/* <img
            src="/vps-db-min.jpg"
            alt="hero-1"
            className={`block object-cover -mt-[0rem] sm:-mt-[5rem] md:-mt-[5rem] lg:-mt-[16rem] ${fade ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}
            style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }}
          /> */}
      <div className="flex flex-col md:flex-row justify-between px-5 py-3 md:py-5 bg-[#828282]">
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-thin">
            One Click Application Setup
          </h2>
          <p className="text-sm text-white">
            Easily deploy Node.js servers, static HTML sites, WordPress, and PHP
            applications with just one click—saving time and reducing
            complexity.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-thin">VHost</h2>
          <p className="text-sm text-white">
            Seamlessly manage multiple websites on a single server through
            virtual hosting, maximizing infrastructure efficiency and
            scalability.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-thin">Github</h2>
          <p className="text-sm text-white">
            Integrate with GitHub for streamlined code deployment, version
            control, and collaboration, ensuring smooth development workflows.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-thin">Free SSL</h2>
          <p className="text-sm text-white">
            Automatically secure your websites with industry-standard SSL
            certificates at no additional cost, building trust and safeguarding
            data.
          </p>
        </div>
      </div>

      {plans?.length > 0 && plans && (
        <PricingPlan plans={allPlans} planss={plans} productLink={"test"} />
      )}
      <FAQSection faqs={faqs} />
    </div>
  );
};

export default VPSPage;
