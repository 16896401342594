import React from "react";
import PropTypes, { number } from "prop-types";
import { TiTick } from "react-icons/ti";
import { Product } from "../types";
import { FormatPrice } from "../util/methods";

interface Plan {
  tier: string;
  cpu: string;
  ram: string;
  drive: string;
  bandwidth: string;
  price: string;
}
interface InputType {
  plans: Plan[];
  planss?: Product[] | null;
  productLink: string;
  className?: string;
}
const PricingPlan = ({ plans, productLink, className, planss }: InputType) => {
  //   console.log("plans", planss);
  return (
    <div className={`mt-20 mb-12 ${className}`}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-center">
        {planss !== null &&
          typeof planss !== "undefined" &&
          planss.map((plan: Product, index: number) => {
            const descriptions = plans[index];
            return (
              <div
                key={index}
                className="rounded-sm p-6 border-custom-2 hover:bg-gray-200 transition duration-300"
              >
                <h2 className="text-xl font-semibold mb-4 text-red-600">
                  {plan.name}
                </h2>
                <div className="mb-4 text-red-600">
                  <span className="text-6xl font-normal mb-1 -mt-5">
                    {`${plan.pricing.NGN.prefix} ${FormatPrice(
                      plan.pricing.NGN.monthly
                    )}`}
                    /<span className="font-bold text-sm">Month</span>
                  </span>
                </div>
                <a
                  href={plan.product_url}
                  className="bg-red-600 text-white py-2 px-4 rounded-full hover:bg-gray-900 transition duration-300"
                >
                  CHOOSE PLAN
                </a>
                <div className="border-bottom-custom-2 mt-5"></div>
                <ul className="my-4">
                  <li className="mb-2">
                    <TiTick className="inline text-red-600 mr-1" />
                    <strong>{descriptions.ram}</strong> Ram
                  </li>
                  <li className="mb-2">
                    <TiTick className="inline text-red-600 mr-1" />
                    <strong>{descriptions.drive}</strong> Drive
                  </li>
                  <li className="mb-2">
                    <TiTick className="inline text-red-600 mr-1" />
                    <strong>{descriptions.bandwidth}</strong> Bandwidth
                  </li>
                </ul>
              </div>
            );
          })}
      </div>
    </div>
  );
};

PricingPlan.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      tier: PropTypes.string.isRequired,
      cpu: PropTypes.string.isRequired,
      ram: PropTypes.string.isRequired,
      drive: PropTypes.string.isRequired,
      bandwidth: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    })
  ).isRequired,
  productLink: PropTypes.string.isRequired,
};

export default PricingPlan;
